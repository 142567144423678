.new-workout {
  &__group__type {
    text-transform: uppercase;
  }
  
  &__add {
    margin-top: 1.5em;
    &__input {
      margin-right: 1em;
    }
  }
}
