.btn-group-item {
  font-size: 13px;
  position: relative;
  border-radius: 9px;
  height: 40px;
  border: none;
  outline: none;
  color: $text-color;
  border: 1px solid transparent;
  padding: 0 12px;
  transition: opacity 500ms, color 200ms;
  position: relative;
  background-color: transparent;

  &__outline {
    position: absolute;
    border-radius: 9px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // box-shadow: $pressed-default;
    box-shadow: $flat-default;
  }

    &:hover {
        border-radius: 9px;
        background: linear-gradient(145deg, #d6d8dc60, #ffffff93);
        // box-shadow: 4px 4px 8px #cacccfa6, -4px -4px 8px #ffffff;
        color: #686a77d2;
    }
  &:active {
    border-radius: 9px;
    background: #eef0f4;
    box-shadow: inset 4px 4px 8px #cacccf, inset -4px -4px 8px #ffffff;
    // .btn-group-item__outline {
    //     box-shadow: inset 4px 4px 8px #cacccf, inset -4px -4px 8px #ffffff;
    // }
  }
}
