.navigation-drawer {
  display: flex;
  flex-direction: column;
  width: 256px;
  box-shadow: $flat-default;
  height: calc(100% - 16px);
  padding: 8px 0;
  .nav-button {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    position: relative;
    color: $text-color;
    svg {
      fill: $text-color;
    }

    &__icon {
      margin-right: 32px;
    }
    
    &__outline{
      left: 3%;
      height: 90%;
      width: 94%;
      position: absolute;
      border-radius: 5px;
      box-shadow: inset 3px 3px 4px #cacccf, inset -1px -5px 9px #ffffff
    }
  }
}
