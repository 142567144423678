.button-group {
  font-size: 13px;
  display: inline-block;
  background-color: $background-color;
  // box-shadow: $flat-default;
  // padding: 0.3em;
  border-radius: 9px;
  &__separator{
    display: none;
  }

  .button {
    margin-right: 0.3em;
    &:last-child {
      margin-right: 0;
    }
  }
}
