.button {
  font-size: 13px;
  border-radius: 9px;
  background: #eef0f4;
  box-shadow: 4px 4px 8px #cacccf, -4px -4px 8px #ffffff;
  height: 40px;
  border: none;
  outline: none;
  color: $text-color;
  border: 1px solid transparent;
  padding: 0 12px;
  transition: opacity 500ms, color 200ms;
  position: relative;

  &:hover {
    border-radius: 9px;
    background: linear-gradient(145deg, #d6d8dc60, #ffffff93);
    box-shadow: 4px 4px 8px #cacccfa6, -4px -4px 8px #ffffff;
    color: #686a77d2;
    // &::before{
    //   content: '';
    //   background-color: red;
    //   height: 100%;
    //   width: 100%;
    //   position: absolute;
    //   top:0;
    //   left: 0;
    // }
  }

  &:focus {
    // border-radius: 9px;
    // background: linear-gradient(145deg, #ffffff, #d6d8dc);
    // box-shadow: 5px 5px 9px #d1d3d7, -5px -5px 9px #ffffff;
    // box-shadow: 4px 4px 8px rgb(47, 112, 209), -4px -4px 8px #ffffff;
    // border: 1px solid #b37400;
    // color: #b37400;
  }

  &:active {
    border-radius: 9px;
    background: #eef0f4;
    box-shadow: inset 4px 4px 8px #cacccf, inset -4px -4px 8px #ffffff;
    // box-shadow: inset 2px 2px 3px #cacccf, inset -2px -2px 3px #ffffff;
  }

  &:disabled {
    //   box-shadow: none;
    opacity: 0.4;
  }

  &--group {
    background: transparent;
    box-shadow: none;
    // &:hover {
    //   border-radius: 9px;
    //   background: linear-gradient(145deg, #d6d8dc60, #ffffff93);
    //   box-shadow: 4px 4px 8px #cacccfa6, -4px -4px 8px #ffffff;

    //   // background: #eef0f4;
    //   // box-shadow: 4px 4px 8px #cacccf, -4px -4px 8px #ffffff;
    // }

    &:active {
      border-radius: 9px;
      background: #eef0f4;
      box-shadow: inset 4px 4px 8px #cacccf, inset -4px -4px 8px #ffffff;
    }

    &.button--selected {
      //   box-shadow: 4px 4px 8px #cacccf, -4px -4px 8px #ffffff;
      //   box-shadow: $flat-default;
    //   &:hover {
    //     box-shadow: none;
    //     // background-color: red;
    //   }
    }
  }

  &--selected {
    //   box-shadow: 4px 4px 8px #cacccf, -4px -4px 8px #ffffff;
    box-shadow: $pressed-default;
    // &:hover{
    //     box-shadow: $flat-default;
    // }
  }
}
