:root {
  --primary-color: #13789A;
  font-family: Arial, Helvetica, sans-serif
}

@import "./common/variables.scss";
@import "./common/allCommon";

@import "./hooks/useRipple";

@import "./profilePage/Profile";

@import "./workoutsPage/Clicker";
@import "./workoutsPage/NewWorkout";
@import "./workoutsPage/WorkoutItem";
@import "./workoutsPage/WorkoutsManager";

@import "./landingPage/LandingPage";

@import "./roomsPage/Room";
@import "./roomsPage/UsersRow";
@import "./roomsPage/Rooms";

@import "./newUserPage/NewUserPage";
