.landing-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &__title{
        font-size: 40px;
        margin-top: -1rem;
    }

    &__demo{
        margin-top: 1rem;
    }
}