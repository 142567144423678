.navigation-rail {
  display: flex;
  flex-direction: column;
  width: 72px;
  box-shadow: $flat-default;
  height: calc(100% - 16px);
  padding: 8px 0;
  .nav-button {
    padding: 0;
    height: 72px;
    color: $text-color;
    svg {
      fill: $text-color;
    }
    opacity: 0.6;
    &--active {
      opacity: 1;
      color: $primary-color;
      svg {
        fill: $primary-color;
      }
    }
  }
}
