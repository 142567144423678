$appBarHeight: 56px;
$navBottomHeight: 56px;
$navDrawerWidth: 256px;
$navRailWidth: 72px;

.main-layout {
  #navDrawer {
    grid-area: navDrawer;
  }

  #navRail {
    grid-area: navRail;
  }

  #appBar {
    grid-area: appBar;
    position: fixed;
    z-index: 1;
    height:$appBarHeight ;
    top: 0;
    left: 0;
    width: 100%;
  }

  #appBar {
    grid-area: appBar;
  }

  #navBottom {
    grid-area: navBottom;
  }

  #appContent {
    overflow: auto;
    padding-top: $appBarHeight + 10px  ;
    display: flex;
    flex-direction: column;
  }

  display: grid;
  height: 100%;
  overflow: hidden;

  grid-template-areas:
    "content"
    "navBottom";
  grid-template-rows: auto $navBottomHeight;
  grid-template-columns: auto;
  grid-gap: 0px;

  &--medium {
    grid-column-gap: 30px;
    grid-template-areas:
      "navRail appBar"
      "navRail content";
    grid-template-rows: $appBarHeight auto;
    grid-template-columns: $navRailWidth auto;
    #appBar {
      position: static;
    }
    #appContent {
      // padding-top: 0px;
      padding: 10px 30px 10px 0; 
    }
  }

  &--large {
    grid-column-gap: 30px;
    grid-template-areas:
      "navDrawer appBar"
      "navDrawer content";
    grid-template-rows: $appBarHeight auto;
    grid-template-columns: $navDrawerWidth auto;
    #appBar {
      position: static;
    }
    #appContent {
      // padding-top: 0px;
      padding: 10px 30px 10px 0; 
    }
  }
}
