@import "all";

body {
  background-color: #eef0f4;
  color: $text-color;
  // height: 100vh;
}

.App {
  /* text-align: center; */
  // max-width: 728px;
  // margin: 0 auto;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  height: 100vh;
  // padding: 0 1.4rem;
}

.header {
  position: fixed;
  top: 0;
  min-height: 85px;
  max-width: 728px;
  display: flex;
  width: calc(100% - 2.8rem);
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 1rem 0;
  box-sizing: border-box;

  &__start {
    position: relative;
  }

  &__center__tabs {
    position: fixed;
    width: calc(100% - 1.4rem);
    left: 0;
    bottom: 0;
    display: flex;
    padding: 0.7rem;
    .separator {
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      width: 100%;
      margin: 0;
    }
    button {
      flex-grow: 1;
    }

    @media only screen and (min-width: $tabs-breakpoint) {
      width: auto;
      padding: 0.3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      .separator{
        display: none;
      }
    }
  }
}

.main-content {
  padding: 0 1rem;
  position: relative;
  flex-grow: 1;
  
  @media (min-width: 600px) {
    padding: 0 0 0 0.5rem;

  }
  // @media (min-width: 1000px) {
  //   padding: 0 1rem;
  // }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;
  color: #686a7747;
  @media only screen and (min-width: $tabs-breakpoint) {
    padding-bottom: 1rem;
  }
}

.header-start-button {
  @media only screen and (min-width: $tabs-breakpoint) {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%);
  }
}

.header-separator {
  margin-top: 5.5rem;
  &--medium {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
}

.header-separator-dynamic {
  display: none;
  margin-top: 5.5rem;
  @media only screen and (min-width: $tabs-breakpoint) {
    display: block;
  }
}
