.profile {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__spacer {
    flex-grow: 1;
  }

  &__version {
    opacity: 0.3;
    margin-bottom: 1rem;
    text-align: center;
  }
}
