[data-animation="ripple"] {
    //  Customize effect by defining the following
    //  variables on the parent element 
     --ripple-background: var(--primary-color, white);
     --ripple-opacity: 0.2;  
     --ripple-duration: 600ms;      
     --ripple-easing: linear;
    
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position:absolute;
        display: block;
        background: var(--ripple-background, white);      
        border-radius: 50%;
        pointer-events: none; 
        
        //  position and size 
        top: calc(var(--y) * 1px);
        left: calc(var(--x) * 1px);
        width:  calc(var(--d) * 1px);
        height: calc(var(--d) * 1px);

        //  animated properties
        opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));                
        transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing,linear);   
        transform: translate(-50%, -50%) scale(var(--s, 1));
        transform-origin: center;
    }
}

.rainbow {
    --ripple-opacity: 1;
    --ripple-background: radial-gradient(circle at center, rebeccapurple, dodgerblue, olive, gold, orange, tomato);
    --ripple-duration: 2000ms;
    --ripple-easing: cubic-bezier(0, .5, .5, 1);    
    background-color: skyblue;
    color: rgba(white, 0.7);

    transition: background-color 300ms, color 300ms;
    &:hover { background-color: darken(skyblue, 10%); color: rgba(white, 1);}
}
