.new-user-page {
  &__name {
      margin-top: 1rem;
      label{
        display: block;
      }
      .textbox{
        margin: 1rem 1rem 0px 0px;
      }
  }
}
