.clicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  &__name {
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &__display {
    border-radius: 50%;
    background: #eef0f4;
    box-shadow: 5px 5px 10px #d9dade, -5px -5px 10px #ffffff;
    height: 200px;
    width: 200px;
    min-height: 200px;
    margin: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__count {
      font-size: 80px;
    }
    &__unit {
      font-size: 16px;
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__keypad {
      .button {
        min-width: 4rem;
        margin: 0.3rem;
      }
    }

    &__clear {
      width: 100%;
      margin: 1.5rem 0.3rem 0.3rem 0.3rem;
    }
  }

  &__actions {
    .button {
      margin: 0.3em;
    }
  }

  &__spacer {
    flex-grow: 1;
  }

  &__id {
    opacity: 0.3;
    margin-bottom: 1rem;
  }
}
