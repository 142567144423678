.succes-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__message {
    margin-top: 1rem;
    font-size: 30px;
    color: rgb(40, 181, 160);
    font-weight: 500;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
