.chart-filters{
    margin: 0 0.2rem;
    &__button{
        margin-left: 0.4rem;
        &.button--selected{
            &:hover{
                box-shadow: none;
            }
        }
    }
}